import { ISbStoryData } from '@storyblok/react'
import { PageStoryblok } from '@/types/storyblok-component-types'

export default function deepSearchStoryblok<T>(
  story: ISbStoryData<PageStoryblok>,
  storyComponent: string,
): T | undefined {
  return deepSearch<T>(story?.content?.body ?? [], storyComponent)
}

function deepSearch<T>(body: any[], storyComponent: string): T | undefined {
  for (const content of body) {
    if (content.component === storyComponent) {
      return content as T
    }

    // body is key of section's children
    if (content.body && Array.isArray(content.body)) {
      const result = deepSearch(content.body, storyComponent)

      if (result) {
        return result as T
      }
    }
  }
}
